<template>
  <div class="reserve-transport">
    <van-form ref="validateForm" :disabled="disabled">
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          举报类型
        </div>
        <van-field v-model="displayType" name="picker" placeholder="请选择" :rules="[{ required: true, message: '请选择举报类型' }]"
          is-link readonly @click="showPicker = true" />

        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker title="举报类型" :columns="reportType" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>
      </div>

      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          标题
        </div>
        <van-field v-model.trim="addComplaintForm.reportName" name="title" :rules="[{ required: true, message: '请输入标题' }]"
          placeholder="请输入" />
      </div>
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          联系人
        </div>
        <van-field v-model.trim="addComplaintForm.reportContactsName" name="contact" placeholder="请输入"
          :rules="[{ required: true, message: '请输入联系人' }]" />
      </div>
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          联系电话
        </div>
        <van-field v-model.trim="addComplaintForm.reportContactsPhone" name="tel" placeholder="请输入" :rules="[
          { required: true, message: '请输入联系电话' },
          { validator: validatePhone, message: '请输入正确格式的联系电话' },
        ]" />
      </div>
      <div class="introduce">
        <div class="label-text-in"><span>*</span>情况说明</div>
        <van-field v-model.trim="addComplaintForm.reportRemarks" rows="4" autosize type="textarea" name="introduce"
          placeholder="请输入" :rules="[{ required: true, message: '请输入情况说明' }]" />
      </div>

      <div class="line-item file-upload">
        <div class="label-text">证明图片</div>
        <van-field name="uploader">
          <template #input>
            <FileUpload :bizPath="'reserve/pic'" @res="afterRead" :value="addComplaintForm.reportImgs"
              :disabled="disabled"></FileUpload>
          </template>
        </van-field>
      </div>

      <!-- 提交按钮 -->
      <div class="btn-box" v-if="!disabled">
        <div class="btn" @click="addComplaint">提交</div>
      </div>
    </van-form>
  </div>
</template>
<script>
import { addComplaint, getComplaintDetails } from "@/api/complaint.js";
import FileUpload from "@/components/fileUpload.vue";
import { showSuccessToast, showFailToast } from "vant";
// import {getImageUrl} from '@/utils/funeral.js';
export default {
  name: "AddForm",
  data() {
    return {
      reportType: [
        { text: "丧事扰民举报", value: "1" },
        { text: "青山白化举报", value: "2" },
      ],
      showPicker: false,
      displayType: "",
      addComplaintForm: {
        reportType: 1,
      },
      image: "",
      complaintId: "",
      disabled: false,

    };
  },
  components: { FileUpload },
  created() {
    let { id } = this.$route.query;
    this.complaintId = id;
    if (this.complaintId) {
      this.disabled = true;
      this.getComplaintDetails();
    }
  },
  mounted() { },
  methods: {
    validatePhone(val) {
      const reg =
        /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-8]|18[0-9]|19[0-9])\d{8}$/;
      return reg.test(val);
    },
    // 获取详情
    async getComplaintDetails() {
      let [err, res] = await this.$awaitWrap(
        getComplaintDetails(this.complaintId)
      );
      if (err) {
        return;
      }
      this.addComplaintForm = res.result;
      // this.addComplaintForm.reportImgs = getImageUrl(res.result.reportImgs)
      this.reportType.forEach((item) => {
        if (item.value == this.addComplaintForm.reportType) {
          this.displayType = item.text;
        }
      });
    },
    addComplaint() {
      this.$refs.validateForm
        .validate()
        .then(() => {
          addComplaint(this.addComplaintForm).then((res) => {
            if (res.code == 200) {
              showSuccessToast(res.result);
              if (res.success) {
                this.$router.push("/complaint/list");
              }
            } else {
              showFailToast(res.result);
            }
          });
        })
        .catch(() => { });
    },
    // 确认选择举报类型
    onConfirm(value, index) {
      console.log(value, index);
      this.displayType = value.selectedOptions[0].text;
      this.addComplaintForm.reportType = value.selectedOptions[0].value;
      this.showPicker = false;
    },
    // 文件读取完成后的回调函数
    async afterRead(file) {
      this.addComplaintForm.reportImgs = file.message;
    },
  },
};
</script>
<!-- 样式同预约接运 -->
<style lang="less" scoped>


/deep/.van-field__control {
  color: rgba(0, 0, 0, 1);
  font-size: 17px;
  font-family: PingFang-SC-Bold;
  text-align: right;
}

/deep/.van-field__error-message {
  text-align: right;
}

.van-cell:after {
  border: 0;
}

.van-cell {
  padding: 0;
}

/deep/.van-uploader__upload {
  margin: 0;
}

.introduce{
  width: calc(100vw - 22px);
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
    /deep/.van-field__control {
      text-align: left !important;
    }
}
.label-text-in {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  height:50px ;
  line-height: 50px;
  span {
    color: #FF0000;
  }
}

@import "../../assets/styles/reserve.less";
</style>